/* Button Styles */
button {
    background-color: #555;
    color: white;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    margin: 2px 5px;
}

button:hover {
    background-color: #777;
}