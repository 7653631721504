/* Navigation */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #333;
}

.nav-links {
    display: flex;
    align-items: center;
}

.nav-links a {
    color: white;
    text-decoration: none;
    padding: 10px;
    font-size: 16px;
    /* Base font size for all links */
    transition: background 0.3s ease-in-out, border-radius 0.3s ease-in-out;
    border-radius: 8px;
}

.nav-links a:hover {
    background-color: grey;
}

/* Mobile Navigation */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 1001;
}

.hamburger span {
    height: 2px;
    background: white;
    margin: 4px 0;
    width: 25px;
}

.nav-links-mobile {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.nav-links-mobile.active {
    display: flex;
}

.nav-links-mobile a {
    color: white;
    font-size: 16px;
    margin: 5px 0;
    text-decoration: none;
}

/* Submenu */
.submenu {
    position: relative;
    display: inline-block;
    transition: height 0.3s ease-in-out;
    text-align: center;
    /* Center submenu trigger content */
}

/* Submenu Trigger */
.submenu-trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center trigger in mobile */
    cursor: pointer;
    color: white;
    font-size: 16px;
    /* Same font size as other links */
    padding: 10px;
    transition: background 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}

.submenu-trigger:hover {
    background-color: grey;
    border-radius: 8px;
}

.submenu-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    /* Center title content */
}

.submenu-title .arrow {
    margin-left: 5px;
}

/* Submenu Content */
.submenu-content {
    display: none;
    /* Hidden by default */
    background-color: #333;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.submenu-content a {
    display: block;
    /* Ensure each link is on a new line */
    color: white;
    text-decoration: none;
    padding: 8px 0;
    /* Add spacing between items */
    text-align: left;
    /* Align submenu items to the left */
    transition: background 0.3s ease-in-out, padding-left 0.3s ease-in-out;
    border-radius: 4px;
}

.submenu-content a:hover {
    background-color: grey;
    padding-left: 5px;
    /* Add left padding only on hover */
}

/* Mobile Submenu Content (expanded state) */
.submenu-content.open {
    display: block;
    /* Show the submenu content */
    margin-top: 10px;
    /* Add spacing when expanded */
}

/* Submenu Content for Desktop */
@media (min-width: 769px) {
    .submenu-content {
        display: none;
        /* Hidden by default */
        position: absolute;
        /* Position dropdown below trigger */
        top: 100%;
        left: 0;
        width: 200px;
        /* Set fixed width for desktop */
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    }

    .submenu:hover .submenu-content {
        display: block;
        /* Show submenu on hover */
    }

    .submenu-title .arrow {
        margin-left: 5px;
    }

    .submenu-content a {
        text-align: left;
        /* Ensure desktop submenu items are left-aligned */
    }
}

/* Mobile Styles */
@media (max-width: 768px) {
    .submenu-content.open {
        display: block;
        /* Push content below */
        position: relative;
        /* Take up space in layout */
    }

    .submenu {
        position: static;
    }

    .submenu-trigger {
        justify-content: center;
        /* Keep the trigger centered */
        font-size: 16px;
        /* Match font size with other links */
    }

    .submenu-title {
        text-align: center;
        font-size: 1.5em;
        /* Ensure title stays centered */
    }
}