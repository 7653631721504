/* Data Table Styles */
.data-table {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    border-collapse: collapse;
    font-size: 1em;
    background-color: #f4f4f4;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.data-table th,
.data-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.data-table thead {
    background-color: #666;
    color: white;
}

.data-table {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    border-collapse: collapse;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    background-color: #f4f4f4;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.data-table th,
.data-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.data-table th.sortable {
    cursor: pointer;
}

.data-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.data-table tr:hover {
    background-color: #ddd;
}

.last-updated {
    margin-top: 10px;
    font-size: 0.9em;
    color: #555;
    text-align: center;
}