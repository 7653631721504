/* Form and Input Styles */
input,
select {
    padding: 8px;
    font-size: 16px;
    /*width: 200px;*/
    border: 1px solid #ccc;
    border-radius: 4px;
}

input:focus {
    border-color: #007bff;
    outline: none;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
    text-align: left;
}

.form-group .radio-group {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.form-group .radio-group label {
    flex: 1;
    text-align: left;
    font-weight: normal;
}

.form-group .radio-group input[type="radio"] {
    margin-right: 5px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
    text-align: left;
}

.form-group .radio-group {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.form-group .radio-group label {
    flex: 1;
    text-align: left;
    font-weight: normal;
}

.form-group .radio-group input[type="radio"] {
    margin-right: 5px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group input:focus {
    border-color: #007bff;
    outline: none;
}

.form-group button {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
}

.form-group p {
    margin-top: 10px;
}

form {
    padding-top: 20px;
}

.results {
    margin-top: 20px;
}

.results p {
    text-align: left;
    font-size: 18px;
    color: #555;
    margin: 10px 0;
}

.calculator {
    margin-bottom: 40px;
}

.input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.controls {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.controls input,
.controls select {
    padding: 8px;
    font-size: 16px;
    width: 200px;
}


input[type="range"] {
    width: calc(100% - 22px);
    padding: 10px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    color: #333;
}

.combined-slider-container {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
}

.radio-option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.radio-option input {
    margin-right: 10px;
}

.slider-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.slider-container span {
    margin-left: 10px;
    font-weight: bold;
    color: #333;
}

.radio-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.radio-container div {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.radio-container input {
    margin-right: 5px;
}

.radio-label {
    display: inline-block;
    color: #333;
}

#result {
    margin-top: 18px;
    font-size: 1.5em;
    text-align: left;
    color: #333;
    font-weight: bold;
}

.group-header {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    color: #333;
}

input[type="text"],
input[type="range"] {
    width: calc(100% - 20px);
    padding: 10px;
    /*margin-top: 5px;*/
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

input[type="submit"],
input[type="button"] {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: white;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
}

input[type="submit"]:hover,
input[type="button"]:hover {
    background-color: #218838;
}

input[type="button"].reset {
    background-color: #dc3545;
}

input[type="button"].reset:hover {
    background-color: #c82333;
}

#result {
    margin-top: 20px;
    text-align: center;
    font-size: 1em;
    color: #333;
}

/*.slider-container {
    display: none;
    overflow: hidden;
}*/

.slider {
    width: calc(100% - 22px);
    margin: 10px 0;
}

.radio-label {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.radio-label input {
    margin-right: 10px;
}

.radio-unit input[type="radio"] {
    display: inline-block;
    margin-right: 5px;
}

.radio-unit label {
    display: inline-block;
    margin-right: 15px;
}

.highlight {
    background-color: yellow;
    font-weight: bold;
}

.results {
    margin-top: 20px;
}

.results p {
    text-align: left;
    font-size: 18px;
    color: #555;
    margin: 10px 0;
}

.calculator {
    margin-bottom: 40px;
}

.filter {
    display: flex;
    align-items: baseline;
}

.filter label {
    margin-right: 10px;
}

.search-container {
    margin-bottom: 20px;
    position: relative;
}

.search-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
}

#search {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.search-results {
    position: absolute;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom: none;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-sizing: border-box;
}

.search-result-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.search-result-item:hover {
    background-color: #f0f0f0;
}

.search-result-item:last-child {
    border-bottom: 1px solid #ddd;
}

.beer-details {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
}

.input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.clear-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #ccc;
}

.clear-btn:hover {
    color: #888;
}