/* Footer Styles */
.footer {
    background-color: #f2f2f2;
    text-align: center;
    border-top: 1px solid #ccc;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0.5rem 0;
}

.social-icons {
    display: flex;
    justify-content: center;
}

.social-icons a {
    text-decoration: none;
    color: black;
    margin: 0 1rem;
}

.social-icons i {
    font-size: 1rem;
}

.float-container {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.float-child {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .social-icons a:link,
.footer .social-icons a:visited,
.footer .social-icons a:hover,
.footer .social-icons a:active {
    color: black;
    text-decoration: none;
}

.footer-hidden {
    opacity: 0;
    transform: translateY(100%);
}

.footer-visible {
    opacity: 1;
    transform: translateY(0);
}