/* Responsive Styles */
@media (max-width: 900px) {
    .nav-links {
        display: none;
    }

    .hamburger {
        display: flex;
    }

    .nav-links-mobile.active {
        display: flex;
    }
}

@media (max-width: 600px) {
    body {
        margin: 10px;
    }

    .container {
        padding: 10px;
    }

    input {
        font-size: 14px;
        padding: 8px;
    }
}

@media (max-width: 600px) {
    .filter {
        flex-direction: column;
        align-items: flex-start;
    }

    .filter label,
    .filter select {
        margin-bottom: 10px;
    }

    .data-table th,
    .data-table td {
        padding: 8px 10px;
        font-size: 12px;
    }

    .data-table th,
    .data-table td:nth-child(3) {
        display: none;
    }

    .data-table {
        font-size: 12px;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: left;
    }

    .nav-links a {
        margin: 10px 0;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        z-index: 1001;
    }

    .hamburger span {
        height: 2px;
        background: white;
        margin: 4px 0;
        width: 25px;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.9);
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .nav-links.active {
        display: flex;
    }

    .nav-links a,
    .nav-links button {
        color: white;
        text-decoration: none;
        margin: 5px 0;
        font-size: 1.5em;
        background: none;
        border: none;
        cursor: pointer;
    }

    .nav-links a:hover,
    .nav-links button:hover {
        text-decoration: underline;
    }

    .close-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 2em;
        color: white;
        cursor: pointer;
        display: none;
    }

    .nav-links.active .close-btn {
        display: block;
    }

    header,
    main,
    footer {
        z-index: 999;
    }

    @media (max-width: 768px) {
        .close-btn {
            display: block;
        }
    }

    @media (min-width: 769px) {
        .close-btn {
            display: none;
        }
    }
}

@media (min-width: 601px) and (max-width: 900px) {

    .data-table th,
    .data-table td {
        padding: 10px 12px;
        font-size: 12px;
    }

    .data-table th,
    .data-table td:nth-child(3) {
        display: table-cell;
    }
}

@media (min-width: 901px) {

    .data-table th,
    .data-table td {
        padding: 15px 20px;
        font-size: 12px;
    }

    .nav-links {
        display: flex;
    }

    .hamburger {
        display: none;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 10px;
    }

    .form-group label,
    .form-group .radio-group label {
        font-size: 14px;
    }

    .form-group input[type="number"] {
        font-size: 14px;
    }

    .results p {
        font-size: 16px;
    }
}

@media (max-width: 600px) {
    body {
        margin: 10px;
    }

    .container {
        padding: 10px;
    }

    h1 {
        font-size: 1.2em;
    }

    input[type="text"],
    input[type="range"],
    input[type="submit"],
    input[type="button"] {
        font-size: 14px;
        padding: 8px;
    }

    #search {
        font-size: 14px;
    }

    .beer-details {
        padding: 10px;
    }

    label {
        font-size: 0.9em;
    }

    #result {
        font-size: 0.9em;
    }

    .slider {
        width: calc(100% - 22px);
    }

    .form-group label,
    .form-group .radio-group label {
        font-size: 14px;
    }

    .form-group input[type="number"] {
        font-size: 14px;
    }

    .results p {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 10px;
    }

    .form-group label,
    .form-group .radio-group label {
        font-size: 12px;
    }

    .form-group input[type="number"] {
        font-size: 12px;
    }

    .results p {
        font-size: 14px;
    }
}