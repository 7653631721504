/* Layout Styles */
.App {
    text-align: center;
    padding-bottom: 100px;
    box-sizing: border-box;
}

.input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.faq-section {
    margin-top: 30px;
}

.faq-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 14px 18px;
    margin: 12px 0;
    cursor: pointer;
    background-color: #ffffff;
    transition: background 0.3s ease-in-out, border 0.3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
}

.faq-item:hover {
    background-color: #f9f9f9;
}

.faq-item.open {
    background-color: #f5f5f5;
    border-left: 5px solid #333;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    color: #333;
    font-size: 16px;
}

.faq-icon {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.faq-answer {
    margin-top: 10px;
    padding: 10px;
    border-top: 1px solid #ddd;
    color: #555;
    font-size: 14px;
    line-height: 1.5;
}